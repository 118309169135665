//We need to inherit directly from the dist rather than just importing
// from "bootstrap", otherwise the entirety of bootstrap gets imported
// stacking on top of its import in the global index, causing the nav
// collapsibles to break
import Toast from "bootstrap/js/dist/toast";

export const showSuccessToast = () => {
    const successToastElement = document.getElementById("success-toast");

    if (successToastElement) {
        var success = new Toast(successToastElement);
        success.show();
    }
};
export const showFailureToast = () => {
    const failureToastElement = document.getElementById("failure-toast");

    if (failureToastElement) {
        var failure = new Toast(failureToastElement);
        failure.show();
    }
};
